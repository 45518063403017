
import { DashboardLayout } from '../components/Layout';

const SettingsPage = () => {
  return (
    <DashboardLayout>
      <h2>Settings Page</h2>
    </DashboardLayout>
  )
}

export default SettingsPage;