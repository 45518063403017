
import { DashboardLayout } from '../components/Layout';

const Netflix = () => {
  return (
    <DashboardLayout>
      <h2>Netflix Page NetflixNetflixNetflix</h2>

    </DashboardLayout>
  )
}

export default Netflix;