import React from 'react';

const BodyWrapper = ({children}) => {
  return (

        <div className="">{children}</div>

  );
};

export default BodyWrapper;
